@orange: #ee791d;

[v-cloak] > * { display:none; }
[v-cloak]::before { 
  content: " ";
  display: block;
  width: 16px;
  height: 16px;
  background-image: url('data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==');
}
.sb-bg-color {
  color: #fff;
  background-color: @orange;
}
.folder {
  border-top: 1px solid #eee!important;
  padding-top: 2em!important;
}
.player {
  padding-left: 0;
  .flag, .name {
    margin-left: 0.25em;
  }
  .icon-youtube-play {
    margin: 0.25em;
  }
}

#my-folders {
  .action-icon {
    font-size: unset;
  }
}
#eligibility {
  .action-icon {
    margin-left: 0;
  }
  h3 {
    font-family: sans-serif;
    margin: 0;
    margin-left: 1em;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.5;
    color: @orange;    
  }
}

// boostrap 4 native styles
.border-0 {
  border: 0;
}
.padding-bottom-0 {
  padding-bottom: 0px;
}
.mr-1 {
  margin-right: 1em!important;
}
.mb-1 {
  margin-bottom: 1em!important;
}
.d-flex {
  display: -ms-flexbox!important;
  display: flex!important;
}
.align-items-center {
  -ms-flex-align: center!important;
  align-items: center!important;
}
.justify-content-between {
  -webkit-box-pack: justify!important;
  -ms-flex-pack: justify!important;
  justify-content: space-between!important;
}
// end of boostrap 4 native styles

// tags
.multiple .vs__dropdown-toggle span {
  .sb-bg-color;
  button {
    margin-left: 0.5em;
    svg {
      fill: #fff;
    }
  }
}


// inline edit
.vue-quick-edit__link--is-clickable {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.5;
  color: @orange!important;
}
.folder-edit {
  -webkit-border-radius: 4px!important;
  -moz-border-radius: 4px!important;
  -o-border-radius: 4px!important;
  border-radius: 4px!important;
}

// draggable 
.handle {
  cursor: grab;
  display: flex;
  align-items: center;
    &.disabled {
      cursor: help;
    }
    a {
      margin-right: 0.5em;
    }
}

// tooltip
.action-icon {
  font-size: 26px;
  cursor: pointer;
  margin-left: 1em;
  vertical-align: middle;
  .glyphicon {
    margin-top: 0.4em;
  }
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 1em;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  //transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  //transition: opacity .15s;
}